import React from 'react'
import { PageProps } from 'gatsby'

import { Text } from '../components/atoms'
import { Layout, SEO } from '../components/organisms/common'
import * as styles from './404.module.scss'

const NotFound: React.FC<PageProps> = () => {
  return (
    <Layout path="/404/">
      <div className={styles.container}>
        <Text as="h1" textType="title">
          404
        </Text>
        <Text as="p" textType="engrave">
          Page Not Found...
        </Text>
      </div>
    </Layout>
  )
}

export default NotFound

export const Head = () => <SEO />
